<template>
	<div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">Рефссылки</h1>
		</div>
		<!-- END page-header -->

		<form class="mw-1200px" @submit.prevent="sendFilter">
			<div class="d-flex flex-wrap gap-3 align-items-end">
				<div class="w-100 w-sm-250px ">
					<InputField
							:type-field="`text`"
							:name-field="`Наименование программы`"
							:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
							v-model="filter.search_program"
					/>
				</div>
				<button type="submit" class="btn btn-primary px-16px py-8px">Поиск</button>
				<button type="button" class="btn btn-gray-100 text-dark px-16px py-8px" @click="resetFilter">Сбросить</button>
			</div>
		</form>

		<div class="d-flex flex-wrap gap-3 align-items-center justify-content-end mb-24px mw-1200px" v-if="items_count">
			Сумма всех заявок по каждому курсу: {{items_count}}
		</div>

		<ReferralsList v-if="items && items.length" />
		<div class="mt-25px" v-if="!loading && !items.length">Реферральные ссылки не найдены</div>

		<div class="pagination mt-4" v-if="pages.length > 1">
			<div class="page-item" :class="page <= 1 ? 'disabled' : ''">
				<router-link :to="'/referrals/?page=' + (page - 1)" class="page-link">«</router-link>
			</div>
			<div class="page-item" v-for="item in pages" :key="item + '_page'">
				<router-link :to="'/referrals/?page=' + item" class="page-link" :class="page.toString() === item.toString() ? 'active' : ''">{{item}}</router-link>
			</div>
			<div class="page-item" :class="page >= last_page ? 'disabled' : ''">
				<router-link :to="'/referrals/?page=' + (page + 1)" class="page-link">»</router-link>
			</div>
		</div>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
  import ReferralsList from '@/components/parts/referrals/ReferralsList'
  import InputField from '@/components/elements/Input'
  const FILTER_FIELDS = {
    search_program: '',
  }

  export default {
    name: 'Referrals',
    components: { InputField, ReferralsList  },

    data() {
      return {
        page: 1,
        filter: JSON.parse(JSON.stringify(FILTER_FIELDS)),
        loading: false,
      }
    },

    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      isContactPerson() {
        return this.user && this.roles.indexOf('contact_person') >= 0
      },
      isMop() {
        return this.user && this.roles.indexOf('sales_department_manager') >= 0
      },
      isRop() {
        return this.user && this.roles.indexOf('head_department_manager') >= 0
      },
      items() {
        return this.$store.getters['referral/items']
      },
      items_count() {
        return this.$store.getters['referral/items_count']
      },
      last_page() {
        return this.$store.getters['referral/last_page']
      },
      pages() {
        let pages = []
        for (let i = 1, l = this.last_page; i <= l; i++) {
          pages.push(i)
        }
        return pages
      },
    },

    watch: {
      $route(to, from) {
        if (to && from && to.query?.page !== from.query?.page) {
          this.page = to.query?.page
          this.getList({ page: this.page })
        }
      },

      itemsGetter(newVal, oldVal) {
        this.items = newVal.map(item => ({ ...item, checked: false }))
      },
    },

    mounted() {
      this.page = this.$route.query?.page || 1
      this.getList({ page: this.page })
    },

    methods: {

      getList(params) {
        this.loading = true
        this.$store.dispatch('referral/fetchReferralItems', params).then(res => {
          this.loading = false
          if (res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('notifications/reset'),
              this.$store.dispatch('documents/resetDocuments'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout'),
            ]).then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
      },

      sendFilter() {
        this.page = 1
        this.getList({ page: this.page, filter: this.removeEmptyValues(JSON.parse(JSON.stringify(this.filter))) })
      },

      removeEmptyValues(object) {
        return typeof (!!object && object === 'object' && !Array.isArray(object))
          ? Object.fromEntries(Object.entries(object).filter(([_, value]) => value))
          : object
      },

      resetFilter() {
        this.page = 1
        this.filter = JSON.parse(JSON.stringify(FILTER_FIELDS))
        this.getList({ page: this.page })
      },

    },
  }
</script>
