<template>
	<div>
		<div :class="isBid ? 'border border-2 border-radius-lg px-24px pt-18px pb-24px mb-24px' : 'mt-30px'" v-if="(documentTypes.length && !isBid || isBid && documentTypes.length && documents.length) && viewDocuments">
			<AddDocumentModal :id-order="idOrder" :id-bid="idBid" :is-bid="isBid" :available-actions="availableActions">
				<template slot="button">
					<a href="javascript:void(0)" class="btn btn-success btn-sm px-16px py-8px">Прикрепить документ</a>
				</template>
			</AddDocumentModal>
			<nav class="step-list d-flex justify-content-between flex-nowrap w-100 mt-30px">

				<template v-for="(item, index) in documentsByTypes">
					<div :class="`step-item${item.file_name ? ' active' : ''}`" :key="`type_${item.id}`">
						<div class="step-dot">{{documentsByTypes.length - index}}</div>
						<div class="step-no">{{item.name}}</div>
						<DocumentHistoryModal v-if="item.file_name" :id-order="idOrder" :id-bid="idBid" :type-document="item.code" :available-actions="availableActions">
							<template slot="button">
								<a :href="item.file_link" :download="item.file_name" class="step-text mt-24px text-decoration-none text-gray-400 d-inline-flex align-items-center">
									<IconDocuments />
									<span class="mx-5px fs-12px">{{item.file_name}}</span>
									<IconInfo />
								</a>
							</template>
						</DocumentHistoryModal>
						<div class="mt-12px" v-if="item.document_id && item.code === 'bid_contract'">
							<div class="form-check" v-if="roles.indexOf('contact_person') >= 0 || roles.indexOf('contact_person') < 0 && item.is_agreed_by_contact_person">
								<span :disabled="item.disabled_agree || !item.can_confirm"
									  :class="`form-check-input cursor-pointer ${roles.indexOf('contact_person') >= 0 &&  item.is_agreed_by_contact_person || roles.indexOf('contact_person') < 0 && item.is_agreed_by_manager ? 'checked' : ''}`"
									  :id="`doc_agree_${item.document_id}`"
									  @click="sendAgree(item.code)" />
								<label :disabled="item.disabled_agree || !item.can_confirm"
									   @click="sendAgree(item.code)"
									   class="form-check-label cursor-pointer" :for="`doc_agree_${item.document_id}`">Согласовано</label>
							</div>
							<div class="form-check mt-12px" v-if="roles.indexOf('contact_person') >= 0 || roles.indexOf('contact_person') < 0 && item.is_signed_by_contact_person">
								<span :disabled="item.disabled_sign || !item.can_sign"
									  :class="`form-check-input cursor-pointer ${roles.indexOf('contact_person') >= 0 &&  item.is_signed_by_contact_person || roles.indexOf('contact_person') < 0 && item.is_signed_by_manager ? 'checked' : ''}`"
									  :id="`doc_sign_${item.document_id}`"
									  @click="sendSign(item.code)" />
								<label :disabled="item.disabled_sign || !item.can_sign"
									   @click="sendSign(item.code)"
									   class="form-check-label cursor-pointer" :for="`doc_sign_${item.document_id}`">Подписано</label>
							</div>
						</div>
						<div class="mt-12px" v-if="item.document_id && item.code === 'bid_bill' && roles.indexOf('contact_person') < 0">
							<div class="form-check">
								<span :disabled="item.disabled_agree || !item.can_confirm"
									  :class="`form-check-input cursor-pointer ${item.is_agreed_by_manager ? 'checked' : ''}`"
									  :id="`doc_agree_${item.document_id}`"
									  @click="sendAgree(item.code)" />
								<label :disabled="item.disabled_agree || !item.can_confirm"
									   @click="sendAgree(item.code)"
									   class="form-check-label cursor-pointer" :for="`doc_agree_${item.document_id}`">Оплачено</label>
							</div>
						</div>
					</div>
					<span class="step-delimer"></span>
				</template>

			</nav>
		</div>
	</div>
</template>

<script>
  import IconDocuments from '@/components/icons/IconDocuments'
  import IconInfo from '@/components/icons/IconInfo'
  import AddDocumentModal from './modal/AddDocumentModal'
  import DocumentHistoryModal from './modal/DocumentHistoryModal'
  import axios from 'axios'

  export default {
    name: 'Documents',
    props: ['idOrder', 'idBid', 'isBid', 'availableActions'],
    components: { DocumentHistoryModal, AddDocumentModal, IconInfo, IconDocuments },
    data() {
      return {
        check_sign_list: {},
        check_agree_list: {},
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ?  this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      token() {
        return this.$store.getters['auth/token']
      },
      documentTypes() {
        return this.$store.getters['documents/types'] || []
      },
      documents() {
        return this.idOrder && this.idBid ? this.$store.getters['documents/itemsBids'](this.idOrder, this.idBid) : []
      },
	  viewDocuments() {
        return this.documents.length || !this.documents.length && this.availableActions && (
          this.availableActions['can-add-bid_contract-document'] ||
          this.availableActions['can-add-bid_additional_agreement-document'] ||
          this.availableActions['can-add-bid_bill-document'] ||
          this.availableActions['can-add-bid_contract-document'] ||
          this.availableActions['can-add-bid_letter_guarantee-document'] ||
          this.availableActions['can-add-bid_payment-document'] ||
          this.availableActions['can-add-bid_protocol_disagreement-document\n']
		)
	  },
      documentsByTypes() {
        let items = []

        if (this.documentTypes.length) {
          this.documentTypes.forEach(type => {
            let tmpItem = type
            if (this.documents.length) {
              this.documents.forEach(item => {
                if (item.type_code === type.code) {
                  tmpItem = {
                    ...type,
                    file_name: item.file_name,
                    file_id: item.id,
                    document_id: item.id,
                    is_signed_by_contact_person: item.is_signed_by_contact_person,
                    is_agreed_by_contact_person: item.is_agreed_by_contact_person,
                    is_signed_by_manager: item.is_signed_by_manager,
                    is_agreed_by_manager: item.is_agreed_by_manager,
                    can_confirm: item.can_confirm,
                    can_sign: item.can_sign,
					disabled_agree: this.roles.indexOf('contact_person') >= 0 ? item.is_agreed_by_contact_person : item.is_agreed_by_manager,
                    disabled_sign: this.roles.indexOf('contact_person') >= 0  ? item.is_signed_by_contact_person : item.is_signed_by_manager,
                  }
                  this.check_sign_list[item.id] = this.roles.indexOf('contact_person') >= 0  ? item.is_signed_by_contact_person : item.is_signed_by_manager
                  this.check_agree_list[item.id] = this.roles.indexOf('contact_person') >= 0  ? item.is_agreed_by_contact_person : item.is_agreed_by_manager
                }
              })
            }
            items.push(tmpItem)
          })
        } else {
          items = JSON.parse(JSON.stringify(this.documentTypes))
        }

        console.log('items', this.documents)

        items.reverse()

        return items
      },
    },
    mounted() {
      console.log('FETCH DOC')
      this.$store.dispatch('documents/fetchOrderBidDocumentsItems', { idOrder: this.idOrder, idBid: this.idBid })
    },
    methods: {
      sendAgree(document_type) {
        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'contact_person':
              path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/documents/${document_type}/confirm`
              break
            case 'sales_department_manager':
              path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/documents/${document_type}/confirm`
              break
            case 'head_department_manager':
              path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/documents/${document_type}/confirm`
              break
          }
        })
        this.submit(path)
      },
      sendSign(document_type) {
        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'contact_person':
              path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/documents/${document_type}/sign`
              break
            case 'sales_department_manager':
              path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/documents/${document_type}/sign`
              break
            case 'head_department_manager':
              path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/documents/${document_type}/sign`
              break
          }
        })
        this.submit(path)
      },
      submit(path) {
        if (!path) return false
        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
		  data: {
            value: 1
		  }
        })
          .then(res => res.data)
          .then(res => {
            if (this.isBid) {
              this.$store.dispatch('bids/fetchBidItem', { idOrder: this.idOrder, idBid: this.idBid })
            } else {
              this.$store.dispatch('orders/fetchOrderItem', this.idOrder)
            }
            this.$store.dispatch('documents/fetchOrderBidDocumentsItems', { idOrder: this.idOrder, idBid: this.idBid })
          })
      },
    },
  }
</script>
