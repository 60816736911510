import axios from 'axios'

export default {
  async fetchListenerDeals({ rootState, commit }, params) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'sales_department_manager':
            path = `/mop/listeners/${params.idListener}/deals`
            break
          case 'head_department_manager':
            path = `/rop/listeners/${params.idListener}/deals`
            break
        }
      })
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          page: params.page || 1,
        },
      })

      await res.data

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_LISTENERS_DEALS_ITEMS', { ...res.data.data, idListener: params.idListener })
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async fetchListenerItemInfo({ rootState, commit }, id) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'sales_department_manager':
            path = `/mop/listeners/${id}/get`
            break
          case 'head_department_manager':
            path = `/rop/listeners/${id}/get`
            break
        }
      })
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_LISTENERS_ITEM',  res.data.data)
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async fetchListenerItemMatter({ rootState, commit }, id) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'sales_department_manager':
            path = `/mop/listeners/${id}/personal_matter`
            break
          case 'head_department_manager':
            path = `/rop/listeners/${id}/personal_matter`
            break
        }
      })
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_LISTENERS_ITEM',  res.data.data)
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async fetchListenerItem({ rootState, commit }, id) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/listener/' + id,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_LISTENERS_ITEM',  res.data.data)
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async fetchListenerOrderItems({ rootState, commit }, params) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/orders/${params.idOrder}/listeners/list`
            break
          case 'sales_department_manager':
            path = `/mop/orders/${params.idOrder}/listeners/list`
            break
          case 'head_department_manager':
            path = `/rop/orders/${params.idOrder}/listeners/list`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_LISTENERS_ORDER_ITEMS',  {items: res.data.data, idOrder: params.idOrder})
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async fetchListenerItems({ rootState, commit }, params) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'sales_department_manager':
            path = `/mop/listeners/list`
            break
          case 'head_department_manager':
            path = `/rop/listeners/list`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          page: params.page
        }
      })

      await res.data

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_LISTENERS_ITEMS', { ...res.data.data, idOrder: params.idOrder })
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async removeListenerItem({ rootState, commit }, id) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/listener/delete',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          id: id
        }
      })

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('REMOVE_LISTENER_ORDER_ITEM',  id)
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  resetListeners({ rootState, commit }) {
    return commit('RESET_LISTENERS')
  }
}
