export default {
  RESET_REFERRAL_ITEMS(state) {
    state = {
      items: [],
      pagination: {
        last_page: 1,
        items_count: 0,
      },
    }
  },
  SET_REFERRAL_ITEMS(state, payload) {
    if (payload.data) payload = payload.data
    if (payload.items) {
      state.items = payload.items
    }
    state.pagination.last_page = payload.pagination ? payload.pagination.page_count : 1
    state.pagination.items_count = payload.pagination ? payload.pagination.items_count : 0
    console.log(state)
  },
}
