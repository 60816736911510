<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'CopyLinkRefferalsModal' + id" size="sm" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Поделиться ссылкой</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('CopyLinkRefferalsModal' + id)"></a>
			</template>

			<div class="input-group">
				<input type="text" class="form-control" name="message" :value="link" :readonly="true" ref="mylink">
				<button class="btn btn-primary" type="button" @click="copy"><i class="fa fa-copy"></i></button>
			</div>

			<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3 mt-25px">
				<button type="button" class="btn btn-primary w-100px btn-lg" @click="$bvModal.hide('CopyLinkRefferalsModal' + id)">
					OK
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'CopyLinkRefferalsModal',
    props: ['button', 'id', 'link'],
    data() {
      return {}
    },
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
      user() {
        return this.$store.getters['user/user']
      },
    },
    watch: {},
    mounted() {
    },
    destroyed() {
    },
    methods: {
      showModal() {
        this.$bvModal.show('CopyLinkRefferalsModal' + this.id)
      },
      copy() {
        let Url = this.$refs.mylink;
        Url.select();
        document.execCommand("copy");
        this.$notify({
          group: 'center',
          title: ``,
          text: `Ссылка скопирована!`,
          type: 'success',
        })
      },
    },
  }
</script>
