<template>
	<div class="table-responsive-md">
		<table class="table mb-0 align-middle">
			<thead>
			<tr>
				<th>Слушатель</th>
				<th>Контактные данные</th>
				<th>Блоки<br />программы</th>
				<th>Стоимость</th>
				<th>Есть<br />ВО/СПО</th>
				<th>Объем<br />часов</th>
				<!--<th>Вид<br />программы</th>-->
				<th>Форма<br />обучения</th>
				<!--<th>Вид документа</th>-->
				<th v-if="isBid">Подтвердить<br />документы</th>
				<th class="w-50px"></th>
				<th class="w-150px"></th>
			</tr>
			</thead>
			<tbody>
			<template v-for="listener in listeners">
				<tr :class="{'border-0': listener.configs && listener.configs.length > 1}" :key="'listener_' + idBid + '_' + listener.id">
					<td class="align-top lh-14" :rowspan="listener.configs && listener.configs.length ? listener.configs.length : false">
						{{[listener.info?.surname, listener.info?.name, listener.info?.patronymic].join(' ')}}
					</td>
					<td class="align-top lh-14" :rowspan="listener.configs && listener.configs.length ? listener.configs.length : false">
						{{$formatPhone(listener.info?.phone)}}<br />
						{{listener.info?.email}}
					</td>
					<template v-if="listener.configs">
						<td  class="pt-4px pb-4px fs-12px lh-14" v-for="(block, index) in listener.configs" v-if="index === 0" :key="idBid + '_block_' + block.id" :title="block.name">
							{{block.name}}
						</td>
					</template>
					<td v-else></td>
					<template v-if="listener.configs">
						<td class="pt-4px pb-4px" v-for="(block, index) in listener.configs" v-if="index === 0" :key="idBid + '_block_price_' + block.id + '_' + listener.id">
							{{$formatPrice(block.price)}}
						</td>
					</template>
					<td v-else></td>
					<td class="align-top lh-14" :rowspan="listener.configs && listener.configs.length ? listener.configs.length : false">
						{{listener.info?.available_vo_spo ? 'Да' : 'Нет'}}
					</td>
					<td class="align-top lh-14">
						{{bid.configuration.hours_volume}}
					</td>
					<!--<td class="align-top lh-14">
						{{listener.configs?.programm_type}}
					</td>-->
					<td class="align-top lh-14">
						{{bid.configuration.study_form.name}}
					</td>
					<!--<td class="align-top lh-14">
						{{listener.configs?.type_document}}
					</td>-->
					<td class="align-top lh-14" :rowspan="listener.configs && listener.configs.length ? listener.configs.length : false" v-if="isBid">
						<div class="form-check mt-8px" v-if="listener.info">
						<span :disabled="listener.info.personal_matter_documents_verified"
							  :class="`form-check-input cursor-pointer ${listener.info.personal_matter_documents_verified ? 'checked' : ''}`"
							  @click="confirmDocuments(listener.id)" />
							<label :disabled="listener.info.personal_matter_documents_verified"
								   @click="confirmDocuments(listener.id)"
								   class="form-check-label cursor-pointer">Согласовано</label>
						</div>
					</td>
					<td class="align-top" :rowspan="listener.configs && listener.configs.length ? listener.configs.length : false" nowrap="nowrap">
						<router-link :to="`/orders/${idOrder}/edit-listener/?ID_BID=${idBid}&ID_PROGRAM=${idProgram}&ID_LISTENER=${listener.id}`" v-if="canEditListeners">
							<IconPen />
						</router-link>
						<DeleteListenerModal
								:id-bid="idBid"
								:id-order="idOrder"
								:id="listener.id"
								:is-bid="isBid"
								:program-name="nameProgram"
								:listener-fio="[listener.info?.surname, listener.info?.name, listener.info?.patronymic].join(' ')" v-if="canEditListeners">
							<template slot="button">
								<IconTrash class="ms-12px" />
							</template>
						</DeleteListenerModal>
					</td>
					<td class="align-top" :rowspan="listener.configs && listener.configs.length ? listener.configs.length : false" nowrap="nowrap">
						<div class="d-flex flex-column align-items-center gap-2">
							<a href="javascript:;" @click.prevent="sendAccesses(listener.id)" class="btn btn-sm btn-primary py-5px px-7px" v-if="canSendAccess">Отправить доступы</a>
							<router-link :to="`/listeners/${listener.id}/info`" v-if="isBid">Личное дело</router-link>
						</div>
					</td>
				</tr>
				<tr :class="{'border-0': index < listener.configs.length - 1}" v-if="listener.configs && listener.configs.length > 1 && index !== 0" v-for="(block, index) in listener.configs" :key="idBid + '_block_price_' + block.id + '_' + listener.id">
					<td class="pt-4px pb-4px fs-12px lh-14">{{block.name}}</td>
					<td class="pt-4px pb-4px">{{$formatPrice(block.price)}}</td>
				</tr>
			</template>
			</tbody>
		</table>
	</div>
</template>

<script>
  import axios from 'axios'

  import IconTrash from '@/components/icons/IconTrash'
  import IconPlus from '@/components/icons/IconPlus'
  import IconPen from '@/components/icons/IconPen'
  import DeleteListenerModal from './modal/DeleteListenerModal'

  export default {
    name: 'ListenersOrderList',
    props: ['listeners', 'bid', 'idOrder', 'idBid', 'idProgram', 'nameProgram', 'isBid', 'canConfirmMatterDocs', 'canEditListeners', 'canSendAccess'],
    components: { DeleteListenerModal, IconPlus, IconTrash, IconPen },
    data() {
      return {}
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      token() {
        return this.$store.getters['auth/token']
      },
    },
	mounted() {
    },
    methods: {
      truncate(str, maxlength) {
        return (str.length > maxlength) ?
          str.slice(0, maxlength - 1) + '…' : str
      },

      confirmDocuments(listener_id) {
        if (!listener_id) return

        this.$emit('setCheckedConfirm', listener_id)

        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'sales_department_manager':
              path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/listeners/${listener_id}/documents/confirm`
              break
            case 'head_department_manager':
              path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/listeners/${listener_id}/documents/confirm`
              break
          }
        })

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
		  data: {
            value: 1
		  }
        })
          .then(res => res.data)
          .then(res => {
            if (res.message && !res.success) {
              this.$notify({
                group: 'center',
                title: ``,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Отправлено`,
                type: 'success',
              })
              this.$store.dispatch('bids/fetchBidItem', { idOrder: this.idOrder, idBid: this.idBid })
            }
          })
      },

      sendAccesses(listener_id) {
        if (!listener_id) return
        axios({
          url: process.env.VUE_APP_API_URL + `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/listeners/${listener_id}/send_access`,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.message && !res.success) {
              this.$notify({
                group: 'center',
                title: ``,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Отправлено`,
                type: 'success',
              })
            }
          })
      },
    },
  }
</script>
