<template>
	<div>
		<div class="mw-1200px mt-24px">
			<div class="table-responsive-md" v-if="items && items.length">
				<div class="table-outer-radius w-100">
					<table class="table table-bordered mb-0 align-middle w-100">
						<thead>
						<tr>
							<th>№<br />Заказа</th>
							<th>№<br />Заявки</th>
							<th v-if="isListener">Название документа</th>
							<th v-if="isMop || isMop || isContactPerson">Тип документа</th>
							<!--<th v-if="isContactPerson">Статус документа</th>-->
							<th v-if="isContactPerson">Дата обновления</th>
							<th v-if="isMop || isMop || isContactPerson">Шаблон док-та</th>
							<th>Документ</th>
							<th v-if="!isListener" width="230">Действия</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="item in items" :key="`document_${item.document.id}`">
							<td>{{item.order_id}}</td>
							<td>{{item.bid_id}}</td>
							<td v-if="isListener">{{item.document?.document_file_name}}</td>
							<td v-if="isMop || isMop || isContactPerson">{{item.document?.document_type_name}}</td>
							<!--<td v-if="isContactPerson">{{item.status?.name}}</td>-->
							<td v-if="isContactPerson">{{item.updated_at}}</td>
							<td v-if="isMop || isMop || isContactPerson">
								<a :href="item.template?.document_file_link" @click.prevent="downloadItem({url: item.template?.document_file_link, label: item.template?.document_type_name + '.' + item.template.document_file_extension })" class="d-inline-flex align-items-center gap-1" v-if="item.template">
									<IconDocuments :width="20" :height="20" />
									<IconDownload />
								</a>
								<!--:download="item.template?.document_type_name"-->
								<!--@click.prevent="downloadItem({url: item.template?.document_file_link, label: item.template?.document_type_name})"-->
							</td>
							<td>
								<a :href="item.document?.document_file_link" @click.prevent="downloadItem({url: item.document?.document_file_link, label: item.document?.document_type_name + '.' + item.document.document_file_extension})" class="d-inline-flex align-items-center gap-1">
									<IconDocuments :width="20" :height="20" />
									<IconDownload />
								</a>
							</td>
							<td v-if="!isListener">
								<div class="d-flex gap-2 align-items-center flex-column">

									<DocumentHistoryModal v-if="item.bid_id" :id-order="item.order_id" :id-bid="item.bid_id" :type-document="item.type.code">
										<template slot="button">
											<a href="javascript:void(0)" class="w-200px justify-content-center btn btn-primary btn-sm px-12px py-8px d-inline-flex align-items-center gap-1">
												<IconInfo :width="16" :height="16" color="#ffffff" />
												История документа
											</a>
										</template>
									</DocumentHistoryModal>
									<RequestDocumentModal :id-order="item.order_id" :id-bid="item.bid_id" :type-document="item.type.code" v-if="isContactPerson && item.type.code === 'bid_contract' && item.bid_id">
										<template slot="button">
											<a href="javascript:;" class="w-200px btn btn-primary btn-sm px-12px py-8px">Создать запрос</a>
										</template>
									</RequestDocumentModal>
									<!--<AddDocumentModal :id-order="item.order_id" :id-bid="item.bid_id" :type-document="item.type.code" v-if="item.bid_id">
										<template slot="button">
											<a href="javascript:void(0)" class="w-200px btn btn-success btn-sm px-12px py-8px">Прикрепить документ</a>
										</template>
									</AddDocumentModal>-->
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import AddDocumentModal from '@/components/parts/order/modal/AddDocumentModal'
  import IconDocuments from '@/components/icons/IconDocuments'
  import IconDownload from '@/components/icons/IconDownload'
  import RequestDocumentModal from '@/components/parts/order/modal/RequestDocumentModal'
  import IconInfo from '@/components/icons/IconInfo'
  import DocumentHistoryModal from '@/components/parts/order/modal/DocumentHistoryModal'
  import axios from 'axios'

  const fileDownload = require('js-file-download')

  export default {
    name: 'DocumentsList',
    components: { DocumentHistoryModal, IconInfo, RequestDocumentModal, IconDownload, IconDocuments, AddDocumentModal },
    props: ['items'],
    data() {
      return {}
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      isContactPerson() {
        return this.user && this.roles.indexOf('contact_person') >= 0
      },
      isMop() {
        return this.user && this.roles.indexOf('sales_department_manager') >= 0
      },
      isRop() {
        return this.user && this.roles.indexOf('head_department_manager') >= 0
      },
    },
    created() {
    },
    mounted() {
    },
    methods: {
      downloadItem({ url, label }) {
        axios({
          url: url,
          method: 'GET',
          responseType: 'blob',
        })
          .then(res => res.data)
          .then(res => {
            fileDownload(res.data, label);
          })
      },
    },
  }
</script>
