<template>
	<svg :width="width" :height="height" :style="styleName" viewBox="0 0 16 16" :class="className" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M1.14258 8.00107C1.14258 4.22973 4.22858 1.1444 7.99991 1.1444C11.7712 1.1444 14.8572 4.22973 14.8572 8.0004C14.8572 11.7737 11.7712 14.8584 7.99991 14.8584C4.22858 14.8584 1.14258 11.7737 1.14258 8.00107ZM2.28591 8.00107C2.28591 11.1444 4.85724 13.7157 7.99991 13.7157C11.1426 13.7157 13.7146 11.1444 13.7146 8.00107C13.7146 4.8584 11.1426 2.28706 7.99991 2.28706C4.85724 2.28706 2.28591 4.8584 2.28591 8.00107ZM8.57125 11.4291H7.42858V6.85707H8.57125V11.4291ZM7.99991 3.99973C8.11447 3.99654 8.2285 4.01634 8.33527 4.05797C8.44204 4.0996 8.53938 4.16222 8.62153 4.24211C8.70369 4.32201 8.76899 4.41756 8.81358 4.52313C8.85817 4.6287 8.88115 4.74213 8.88115 4.85673C8.88115 4.97133 8.85817 5.08477 8.81358 5.19033C8.76899 5.2959 8.70369 5.39146 8.62153 5.47135C8.53938 5.55125 8.44204 5.61386 8.33527 5.65549C8.2285 5.69712 8.11447 5.71693 7.99991 5.71373C7.77856 5.705 7.56918 5.61092 7.41565 5.45122C7.26213 5.29152 7.17638 5.07859 7.17638 4.85707C7.17638 4.63554 7.26213 4.42261 7.41565 4.26291C7.56918 4.10321 7.77856 4.00847 7.99991 3.99973Z" :fill="color"/>
	</svg>

</template>

<script>
  export default {
    name: 'IconInfo',
	props: {
      'width': {
		type: Number,
		default: 16,
	  },
	  'height': {
        type: Number,
        default: 16,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#2B2A29'
	  },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
