<template>
	<div>
		<div class="mb-25px">
			<router-link to="/listeners/" class="d-inline-flex align-items-center text-decoration-none text-dark">
				<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
					<path d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z" fill="#2B2A29" />
				</svg>
				Назад
			</router-link>
		</div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between gap-3 mb-15px mw-1200px">
			<h1 class="page-header mb-0">Сделки</h1>
		</div>
		<div class="d-flex align-items-center flex-wrap gap-3 mb-20px" v-if="item">
			<span class="text-dark-300">Слушатель:</span>
			{{[item.surname, item.name, item.patronymic].join(' ')}}
		</div>
		<!-- END page-header -->
		<div class="mw-1200px">
			<div class="table-responsive-md w-100" v-if="deals.length">
				<div class="table-outer-radius w-100">
					<table class="table table-bordered mb-0 align-middle w-100">
						<thead>
						<tr>
							<th>№Заказа</th>
							<th>№Заявки</th>
							<th>Роль</th>
							<th>Процесс</th>
							<th>Статус выдачи документа об образовании</th>
							<th>Документ об образовании</th>
							<th>Делопроизводство</th>
						</tr>
						</thead>
						<tbody>
						<template v-for="deal in deals">
							<tr v-for="bid in deal.bids" :key="`deal_${deal.id}_${bid.id}`">
								<td>
									{{deal.order_id}}
								</td>
								<td>
									{{bid.id}}
								</td>
								<td>
									{{bid.roles.join(', ')}}
								</td>
								<td>
									{{bid.process}}
								</td>
								<td>
									{{bid.document_issue_status}}
								</td>
								<td>
									{{bid.document_education}}
								</td>
								<td>
									{{bid.office_work_documents}}
								</td>
							</tr>
						</template>
						</tbody>
					</table>
				</div>
			</div>

			<div class="pagination mt-4" v-if="pages.length > 1">
				<div class="page-item" :class="page <= 1 ? 'disabled' : ''">
					<router-link :to="'/listeners/' + this.idListener + 'deals?page=' + (page - 1)" class="page-link">«</router-link>
				</div>
				<div class="page-item" v-for="item in pages" :key="item + '_page'">
					<router-link :to="'/listeners/' + this.idListener + 'deals?page=' + item" class="page-link" :class="page.toString() === item.toString() ? 'active' : ''">{{item}}</router-link>
				</div>
				<div class="page-item" :class="page >= last_page ? 'disabled' : ''">
					<router-link :to="'/listeners/' + this.idListener + 'deals?page=' + (page + 1)" class="page-link">»</router-link>
				</div>
			</div>
		</div>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
  export default {
    name: 'DBListenersPersonalDeals',
    components: {},
    data() {
      return {
        page: 1,
      }
    },

    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      isContactPerson() {
        return this.user && this.roles.indexOf('contact_person') >= 0
      },
      isMop() {
        return this.user && this.roles.indexOf('sales_department_manager') >= 0
      },
      isRop() {
        return this.user && this.roles.indexOf('head_department_manager') >= 0
      },
      idListener() {
        return this.$route.params.id
      },
      deals() {
        return this.idListener ? this.$store.getters['listeners/deals'] : null
      },
      last_page() {
        return this.$store.getters['listeners/deals_last_page']
      },
      pages() {
        let pages = []
        for (let i = 1, l = this.last_page; i <= l; i++) {
          pages.push(i)
        }
        return pages
      },
      item() {
        return this.idListener ? this.$store.getters['listeners/itemById'](this.idListener) : null
      },
    },

    watch: {
      $route(to, from) {
        if (to && from && to.query?.page !== from.query?.page) {
          this.page = to.query?.page
          this.getList({ page: this.page })
        }
      },
    },

    mounted() {
      this.page = this.$route.query?.page || 1
      this.getList({ page: this.page })
      this.$store.dispatch('listeners/fetchListenerItemMatter', this.idListener)
    },

    methods: {

      getList(params) {
        this.loading = true
        this.$store.dispatch('listeners/fetchListenerDeals', { idListener: this.idListener, ...params }).then(res => {
          this.loading = false
          if (res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('notifications/reset'),
              this.$store.dispatch('documents/resetDocuments'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout'),
            ]).then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
      },

    },
  }
</script>
