export default {
  items: [],
  items_order: [],
  deals: [],
  pagination: {
    last_page: 1,
    deals: {
      last_page: 1
    }
  }
}
