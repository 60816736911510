<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'DeleteRefferalsModal' + id" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Удалить реферальную ссылку?</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('DeleteRefferalsModal' + id)"></a>
			</template>

			<div class="text-center mt-n3 mb-3">Отменить это действие будет невозможно</div>

			<div slot="modal-footer" class="d-flex justify-content-between w-100 gap-3">
				<button type="button" class="btn btn-primary btn-lg flex-1" @click="send">
					Удалить
				</button>
				<button type="button" class="btn btn-gray btn-lg text-dark flex-1" @click="$bvModal.hide('DeleteRefferalsModal' + id)">
					Отменить
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DeleteRefferalsModal',
    props: ['button', 'id'],
    data() {
      return {}
    },
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
      user() {
        return this.$store.getters['user/user']
      },
    },
    watch: {},
    mounted() {
    },
    destroyed() {
    },
    methods: {
      showModal() {
        this.$bvModal.show('DeleteRefferalsModal' + this.id)
      },
      send() {
        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'sales_department_manager':
              path = `/mop/referral/link/${this.id}/delete`
              break
            case 'head_department_manager':
              path = `/rop/referral/link/${this.id}/delete`
              break
          }
        })
        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res) {
              this.$bvModal.hide('DeleteRefferalsModal' + this.id)
              this.$store.dispatch('referral/fetchReferralItems')
            }
          })
      },
    },
  }
</script>
