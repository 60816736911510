<template>
	<div>
		<div class="mw-100">
			<div class="table-responsive-md" v-if="items && items.length">
				<div class="table-outer-radius">
					<table class="table table-bordered mb-0 align-middle">
						<thead>
						<tr>
							<th width="300">Наименование программы</th>
							<th width="400">Блоки<br />программы</th>
							<th>Объем<br />часов</th>
							<th>Форма<br />обучения</th>
							<th>Стоимость</th>
							<th>Вид<br />программы</th>
							<th>Вид<br />документа</th>
							<th>Кол-во<br />переходов</th>
							<th>Кол-во<br />заказов</th>
							<th width="197">Действия</th>
							<th colspan="w-50px"></th>
						</tr>
						</thead>
						<tbody>
						<template v-for="item in items" v-if="item.program">
							<tr :class="{'border-0': item.program.configurations && item.program.configurations.length > 1}" :key="`item_${item.id}`">
								<td class="align-top" :rowspan="item.program.configurations && item.program.configurations.length ? item.program.configurations.length : false">{{item.program.name}}</td>
								<template v-for="(configuration, index) in item.program.configurations" v-if="item.program.configurations && index === 0">
									<td class="pt-4px pb-4px" :key="`configuration_${configuration.id}`">
										<template v-for="group_program in configuration.group_program">
											<span v-for="block in group_program.blocks" :key="`config_${configuration.id}_block_${block.id}`">{{block.name}}<br/></span>
										</template>
									</td>
									<td class="pt-4px pb-4px">{{configuration.hours_volume}}</td>
									<td class="pt-4px pb-4px">{{configuration.study_form?.name}}</td>
									<td class="pt-4px pb-4px">{{$formatPrice(configuration.price)}}</td>
								</template>
								<td class="align-top" :rowspan="item.program.configurations && item.program.configurations.length ? item.program.configurations.length : false">
									{{item.program.program_type?.name}}
								</td>
								<td class="align-top" :rowspan="item.program.configurations && item.program.configurations.length ? item.program.configurations.length : false">
									{{item.program.issued_document_type?.name}}
								</td>
								<td class="align-top" :rowspan="item.program.configurations && item.program.configurations.length ? item.program.configurations.length : false">
									{{item.number_transitions}}
								</td>
								<td class="align-top" :rowspan="item.program.configurations && item.program.configurations.length ? item.program.configurations.length : false">
									{{item.orders_count}}
								</td>
								<td class="align-top" :rowspan="item.program.configurations && item.program.configurations.length ? item.program.configurations.length : false">
									<CopyLinkRefferalsModal :id="item.id" :link="item.link">
										<template slot="button">
											<a href="javascript:void(0)" class="btn btn-primary btn-sm px-12px py-8px">Сгенерировать ссылку</a>
										</template>
									</CopyLinkRefferalsModal>
								</td>
								<td class="align-top" :rowspan="item.program.configurations && item.program.configurations.length ? item.program.configurations.length : false">
									<DeleteRefferalsModal :id="item.id">
										<template slot="button">
											<IconTrash class-name="mt-7px" />
										</template>
									</DeleteRefferalsModal>
								</td>
							</tr>
							<tr :class="{'border-0': index < item.program.configurations.length - 1}" v-for="(configuration, index) in item.program.configurations" :key="item.id + '_program_config_' + configuration.id" v-if="item.program.configurations && item.program.configurations.length > 1 && index !== 0">
								<td class="pt-4px pb-4px">
									<template v-for="group_program in configuration.group_program">
										<span v-for="block in group_program.blocks" :key="`config_${configuration.id}_block_${block.id}`">{{block.name}}<br/></span>
									</template>
								</td>
								<td class="pt-4px pb-4px">{{configuration.hours_volume}}</td>
								<td class="pt-4px pb-4px">{{configuration.study_form?.name}}</td>
								<td class="pt-4px pb-4px">{{$formatPrice(configuration.price)}}</td>
							</tr>
						</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import IconInfo from '@/components/icons/IconInfo'
  import IconChat from '@/components/icons/IconChat'
  import axios from 'axios'
  import DeleteRefferalsModal from '@/components/parts/referrals/DeleteRefferalsModal'
  import IconTrash from '@/components/icons/IconTrash'
  import CopyLinkRefferalsModal from '@/components/parts/referrals/CopyLinkRefferalsModal'

  export default {
    name: 'ReferralsList',
    components: { CopyLinkRefferalsModal, IconTrash, DeleteRefferalsModal, IconChat, IconInfo },
    data() {
      return {}
    },
    computed: {
      items() {
        return this.$store.getters['referral/items']
      },
    },
    created() {
    },
    mounted() {
    },
    methods: {},
  }
</script>
