<template>
	<div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">Обращения</h1>
		</div>
		<!-- END page-header -->

		<form class="mw-1200px" @submit.prevent="sendFilter">
			<div class="d-flex flex-wrap gap-3 align-items-end">
				<div class="w-100 w-sm-270px">
					<label class="form-label">Статус обращения</label>

					<v-select :options="ticketStatusList" :reduce="item => item.code" label="label" :clearable="false" :searchable="false" v-model="filter.filter_status_id"></v-select>
				</div>
				<div class="w-100 w-sm-270px">
					<label class="form-label">Отдел</label>

					<v-select :options="departmentsList" :reduce="item => item.code" label="label" :clearable="false" :searchable="false" v-model="filter.filter_department_id"></v-select>
				</div>
				<button type="submit" class="btn btn-primary px-12px py-8px ms-sm-auto">Поиск</button>
				<button type="button" class="btn btn-gray-100 text-dark px-12px py-8px" @click="resetFilter">Сбросить</button>
			</div>
		</form>

		<TicketsList :items="items" />
		<div class="mt-25px" v-if="!loading && !items.length">Обращения не найдены</div>

		<ul class="pagination mt-4" v-if="pages.length > 1">
			<div class="page-item" :class="page <= 1 ? 'disabled' : ''">
				<router-link :to="'/tickets/?page=' + (page - 1)" class="page-link">«</router-link>
			</div>
			<div class="page-item" v-for="item in pages" :key="item + '_page'">
				<router-link :to="'/tickets/?page=' + item" class="page-link" :class="page.toString() === item.toString() ? 'active' : ''">{{item}}</router-link>
			</div>
			<div class="page-item" :class="page >= last_page ? 'disabled' : ''">
				<router-link :to="'/tickets/?page=' + (page + 1)" class="page-link">»</router-link>
			</div>
		</ul>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
  import InputField from '@/components/elements/Input'
  import axios from 'axios'
  import DBListenersList from '@/components/parts/dblisteners/DBListenersList'
  import TicketsList from '@/components/parts/tickets/TicketsList'

  const FILTER_FIELDS = {
    filter_status_id: '',
    filter_department_id: '',
  }

  export default {
    name: 'Tickets',
    components: { TicketsList, DBListenersList, InputField },

    data() {
      return {
        page: 1,
        filter: JSON.parse(JSON.stringify(FILTER_FIELDS)),
        loading: false,
      }
    },

    computed: {
      items() {
        return this.$store.getters['tickets/items']
      },
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      isContactPerson() {
        return this.user && this.roles.indexOf('contact_person') >= 0
      },
      isMop() {
        return this.user && this.roles.indexOf('sales_department_manager') >= 0
      },
      isRop() {
        return this.user && this.roles.indexOf('head_department_manager') >= 0
      },
      last_page() {
        return this.$store.getters['listeners/last_page']
      },
      pages() {
        let pages = []
        for (let i = 1, l = this.last_page; i <= l; i++) {
          pages.push(i)
        }
        return pages
      },
      departmentsList() {
        let items = this.$store.getters['dictionaries/items']('departments')
        return items && items.length ? items.map(item => ({ code: item.id, label: item.name })) : []
      },
      ticketStatusList() {
        let items = this.$store.getters['dictionaries/items']('ticket_status')
        return items && items.length ? items.map(item => ({ code: item.id, label: item.name })) : []
      },
    },

    watch: {
      $route(to, from) {
        if (to && from && to.query?.page !== from.query?.page) {
          this.page = to.query?.page
          this.getList({ page: this.page })
        }
      },
    },

    mounted() {
      this.page = this.$route.query?.page || 1
      this.getList({ page: this.page })

      this.$store.dispatch('dictionaries/fetchDictionary', { type: 'ticket_status' })
      this.$store.dispatch('dictionaries/fetchDictionary', { type: 'departments' })
    },

    methods: {

      getList(params) {
        this.loading = true
        this.$store.dispatch('tickets/fetchTicketsItems', params).then(res => {
          this.loading = false
          if (res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('notifications/reset'),
              this.$store.dispatch('documents/resetDocuments'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout'),
            ]).then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
      },

      sendFilter() {
        this.page = 1
        this.getList({ page: this.page, filter: this.removeEmptyValues(JSON.parse(JSON.stringify(this.filter))) })
      },

      removeEmptyValues(object) {
        return typeof (!!object && object === 'object' && !Array.isArray(object))
          ? Object.fromEntries(Object.entries(object).filter(([_, value]) => value))
          : object
      },

      resetFilter() {
        this.page = 1
        this.filter = JSON.parse(JSON.stringify(FILTER_FIELDS))
        this.getList({ page: this.page })
      },

      exportCSV() {
        let checkedItems = [...this.items.filter(item => item.checked)]
        if (!checkedItems.length) {
          this.$notify({
            group: 'center',
            title: ``,
            text: `Выберите слушателей`,
            type: 'error',
          })
        } else {
          axios({
            url: process.env.VUE_APP_API_URL + ``,
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            },
            data: {
              ids: checkedItems.map(item => item.id),
            },
          })
            .then(res => res.data)
            .then(res => {
              if (res.data) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: res.data?.message,
                  type: 'success',
                })
              }
            })
        }
      },

    },
  }
</script>
