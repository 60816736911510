<template>
	<div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">{{isContactPerson ? 'Бухгалтерские документы' : isListener ? 'Документы по образовательному процессу' : isRop || isMop ? 'Документооборот' : 'Документы'}}</h1>
		</div>
		<!-- END page-header -->

		<div class="d-flex gap-3 align-items-stretch mb-24px mw-1200px" v-if="isContactPerson">
			<div class="border border-2 border-radius-lg px-16px py-16px flex-1">
				<div class="fs-16px fw-600 mb-10px">Есть замечания по документу?</div>
				<div class="fs-12px fw-500">
					Если у вас есть замечания по документу, создайте запрос на внесение изменений с помощью кнопки “Создать запрос”
				</div>
			</div>
			<div class="border border-2 border-radius-lg px-16px py-16px flex-1" v-if="manager">
				<div class="d-flex align-items-center">
					<div class="w-65px h-65px rounded-pill overflow-hidden d-flex align-items-center justify-content-center fs-16px bg-gray-10">
						{{manager.surname[0]}}{{manager.name[0]}}
					</div>
					<div class="ms-12px me-auto">
						<div class="fs-12px fw-500">Ваш менеджер</div>
						<div class="mt-6px fs-14px fw-500">{{[manager.surname, manager.name, manager.patronymic].join(' ')}}</div>
						<div class="mt-6px fs-12px fw-500" v-if="manager.phone || manager.email">
							<a :href="'tel:' + manager.phone" v-if="manager.phone">{{$formatPhone(manager.phone)}}</a>
							<a :href="'mailto:' + manager.email" v-if="manager.email">{{manager.email}}</a>
						</div>
					</div>
					<a href="javascript:;" class="btn btn-primary btn-sm py-8px px-16px">Написать в чат</a>
				</div>
			</div>
		</div>

		<form class="mw-1200px" v-if="!isListener" @submit.prevent="sendFilter">
			<div class="d-flex flex-wrap gap-3 align-items-end">
				<template v-if="isMop || isRop">
					<div class="w-100 w-sm-150px ">
						<InputField
								:type-field="`text`"
								:name-field="`Номер заказа`"
								:filter-field="`[0-9]`"
								v-model="filter.filter_order_id"
						/>
					</div>
					<div class="w-100 w-sm-150px ">
						<InputField
								:type-field="`text`"
								:name-field="`Номер заявки`"
								:filter-field="`[0-9]`"
								v-model="filter.filter_bid_id"
						/>
					</div>
				</template>
				<div class="w-100 w-sm-300px">
					<label class="form-label">Тип документа</label>

					<v-select :options="documentTypes" :reduce="item => item.code" label="name" :clearable="false" :searchable="false" v-model="filter.filter_document_type"></v-select>
				</div>
				<!--<template v-if="isContactPerson">
				&lt;!&ndash;	<div class="w-100 w-sm-250px">
						<label class="form-label">Статус документа</label>

						<v-select :options="documentTypes" :reduce="item => item.code" label="name" :clearable="false" :searchable="false" v-model="filter.filter_status_document"></v-select>
					</div>&ndash;&gt;
					<div class="w-100 w-sm-150px">
						<label class="form-label">Подписание</label>

						<v-select :options="signedTypeList" :reduce="item => item.code" label="label" :clearable="false" :searchable="false" v-model="filter.filter_signed_document"></v-select>
					</div>
				</template>-->
				<button type="submit" class="btn btn-primary px-12px py-8px ms-sm-auto">Поиск</button>
				<button type="button" class="btn btn-gray-100 text-dark px-12px py-8px" @click="resetFilter">Сбросить</button>
			</div>
		</form>

		<DocumentsList :items="items" />

		<ul class="pagination mt-4" v-if="pages.length > 1">
			<div class="page-item" :class="page <= 1 ? 'disabled' : ''">
				<router-link :to="'/documents/?page=' + (page - 1)" class="page-link">«</router-link>
			</div>
			<div class="page-item" v-for="item in pages" :key="item + '_page'">
				<router-link :to="'/documents/?page=' + item" class="page-link" :class="page.toString() === item.toString() ? 'active' : ''">{{item}}</router-link>
			</div>
			<div class="page-item" :class="page >= last_page ? 'disabled' : ''">
				<router-link :to="'/documents/?page=' + (page + 1)" class="page-link">»</router-link>
			</div>
		</ul>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>

  import DocumentsList from '@/components/parts/documents/DocumentsList'
  import InputField from '@/components/elements/Input'
  const FILTER_FIELDS = {
    filter_document_type: null,
    filter_status_document: null,
    filter_signed_document: null,
    filter_order_id: '',
    filter_bid_id: '',
  }

  export default {
    name: 'Documents',
    components: { InputField, DocumentsList },

    data() {
      return {
        page: 1,
        filter: JSON.parse(JSON.stringify(FILTER_FIELDS)),
		signedTypeList: [{label: 'Подписан', code: 1}, {label: 'Не подписан', code: 0 }]
	  }
    },

    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      manager() {
        return this.$store.getters['user/manager']
      },
      roles() {
        return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      isContactPerson() {
        return this.user && this.roles.indexOf('contact_person') >= 0
      },
      isMop() {
        return this.user && this.roles.indexOf('sales_department_manager') >= 0
      },
      isRop() {
        return this.user && this.roles.indexOf('head_department_manager') >= 0
      },
      items() {
        return this.$store.getters['documents/items']
      },
      last_page() {
        return this.$store.getters['documents/last_page']
      },
      pages() {
        let pages = []
        for (let i = 1, l = this.last_page; i <= l; i++) {
          pages.push(i)
        }
        return pages
      },

      documentTypes() {
        return this.$store.getters['documents/types']
      },
    },

    watch: {
      $route(to, from) {
        if (to && from && to.query?.page !== from.query?.page) {
          this.page = to.query?.page
          this.getList({ page: this.page })
        }
      },
    },

    mounted() {
      console.log(this.$route)
      if (this.isListener) {
//        this.$router.replace({ name: 'DocumentsListener' })
      }
      this.$store.dispatch('documents/fetchDocumentsTypes')
	  if (this.isContactPerson) {
        this.$store.dispatch('user/fetchGetManager')
      }
      this.page = this.$route.query?.page || 1
      this.getList({ page: this.page })
    },

    methods: {

      getList(params) {
        this.loading = true
        this.$store.dispatch('documents/fetchDocumentsItems', params).then(res => {
          this.loading = false
          if (res && res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout'),
            ]).then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
      },

      sendFilter() {
        this.page = 1
        this.getList({ page: this.page, filter: this.removeEmptyValues(JSON.parse(JSON.stringify(this.filter))) })
      },

      removeEmptyValues(object) {
        return typeof (!!object && object === 'object' && !Array.isArray(object))
          ? Object.fromEntries(Object.entries(object).filter(([_, value]) => value))
          : object
      },

      resetFilter() {
        this.page = 1
        this.filter = JSON.parse(JSON.stringify(FILTER_FIELDS))
        this.getList({ page: this.page })
      },
    },
  }
</script>
