<template>
	<div>
		<div class="mb-25px">
			<router-link to="/listeners/" class="d-inline-flex align-items-center text-decoration-none text-dark">
				<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
					<path d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z" fill="#2B2A29" />
				</svg>
				Назад
			</router-link>
		</div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between gap-3 mb-30px mw-1200px">
			<h1 class="page-header mb-0">Личное дело</h1>
			<a :href="`mailto:${item.email}`" class="btn btn-success" target="_blank" v-if="item">Отправить письмо</a>
		</div>
		<!-- END page-header -->
		<div class="mw-1200px">
			<div class="table-responsive-md w-100" v-if="item">
				<div class="table-outer-radius w-100">
					<table class="table table-bordered mb-0 align-middle w-100">
						<thead>
						<tr>
							<th>СНИЛС</th>
							<th>Фамилия</th>
							<th>Имя</th>
							<th>Отчество</th>
							<th>Телефон</th>
							<th>Эл. почта</th>
							<th class="w-100px">Действия</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="nowrap">{{item.snils.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)}}</td>
							<td>{{item.surname}}</td>
							<td>{{item.name}}</td>
							<td>{{item.patronymic}}</td>
							<td class="nowrap">{{$formatPhone(item.phone)}}</td>
							<td class="nowrap">{{item.email}}</td>
							<td class="px-8px pt-8px pb-8px ">
								<div class="d-flex align-items-center gap-2">
									<!--<IconPen />-->
									<b-dropdown toggle-class="p-0" variant="link" right>
										<template #button-content>
											<a href="javascript:;" class="btn btn-primary btn-sm px-12px py-8px">Отправить сообщение</a>
										</template>
										<a href="javascript:;" class="dropdown-item py-8px lh-14">Начать чат</a>
										<a href="javascript:;" class="dropdown-item py-8px lh-14" @click="sendAccesses(item.id)">Отправить доступы</a>
										<a href="javascript:;" class="dropdown-item py-8px lh-14" @click="sendRequest(item.id)">Отправить запрос<br/>на внесение изменений в ЛД</a>
									</b-dropdown>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>

			<template v-if="item && item.documents?.length">
				<div class="columns-2">
					<template v-for="field in item.documents">
						<div class="columns-item mb-15px position-relative" :key="`documents_form_${field.id}`">
							<label class="form-label">{{field.label}}</label>
							<div class="border border-radius-sm py-8px px-16px d-flex align-items-center justify-content-between">
								<IconDocuments :width="18" :height="18" />
								<span class="ms-12px me-auto">{{field.type_name}}</span>
								<a class="text-decoration-underline" :href="field.file_link" :download="field.file_name">Скачать</a>
							</div>
						</div>
					</template>

				</div>
			</template>
		</div>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
  import axios from 'axios'
  import IconPen from '@/components/icons/IconPen'
  import IconDocuments from '@/components/icons/IconDocuments'

  export default {
    name: 'DBListenersPersonalMatter',
    components: { IconDocuments, IconPen },
    data() {
      return {
      }
    },

    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      isContactPerson() {
        return this.user && this.roles.indexOf('contact_person') >= 0
      },
      isMop() {
        return this.user && this.roles.indexOf('sales_department_manager') >= 0
      },
      isRop() {
        return this.user && this.roles.indexOf('head_department_manager') >= 0
      },
      idListener() {
        return this.$route.params.id
      },
      item() {
        return this.idListener ? this.$store.getters['listeners/itemById'](this.idListener) : null
      },
    },

    watch: {
    },

    mounted() {
      this.$store.dispatch('listeners/fetchListenerItemMatter', this.idListener)
    },

    methods: {
      sendAccesses( listener_id) {
        if (!listener_id) return

        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'sales_department_manager':
              path = `/mop/listeners/${listener_id}/send_access`
              break
            case 'head_department_manager':
              path = `/rop/listeners/${listener_id}/send_access`
              break
          }
        })

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.message && !res.success) {
              this.$notify({
                group: 'center',
                title: ``,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Отправлено`,
                type: 'success',
              })
            }
          })
      },
      sendRequest(listener_id) {
        if (!listener_id) return

        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'sales_department_manager':
              path = `/mop/listeners/${listener_id}/send_request_change_pm`
              break
            case 'head_department_manager':
              path = `/rop/listeners/${listener_id}/send_request_change_pm`
              break
          }
        })

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.message && !res.success) {
              this.$notify({
                group: 'center',
                title: ``,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Отправлено`,
                type: 'success',
              })
            }
          })
      },
    },
  }
</script>
